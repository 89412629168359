import VerifiedIcon from "@mui/icons-material/Verified";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { IconButton } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import HideComponentWrapper from "../../components/HideComponentWrapper";
import { IRemainder } from "../../redux/action";
import { iconsList } from "../../utils/constant";
import { getAccountIcon, showAmount } from "../../utils/functions";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DrawerWrapper from "../../components/drawer/DrawerWrapper";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
interface IRemainderCard {
	handleClickEdit: (id: string) => void;
	handleEditItem: () => void;
	data: IRemainder;
	handleClickPay: (id: string) => void;
	handleDeleteItem: () => void;
}

const RemainderCard = (props: IRemainderCard) => {
	const [state, setState] = useState<IRemainder>({});
	const [showMoreOptions, setShowMoreOptions] = useState<boolean>(false);

	useEffect(() => {
		let data = {
			...props.data
		};
		if (data.status === "inactive") {
		} else if (data.billingDate && moment(data.billingDate).format("YYYY-MM") === moment().format("YYYY-MM")) {
			data.isPaid = true;
		} else if (
			data.billingDate &&
			data.billingCycle === "yearly" &&
			moment(data.billingDate).format("YYYY-MM") < moment(data.lastDate, "DD").format("YYYY-MM")
		) {
			data.isPaid = true;
		} else {
			data.isPaid = false;
			if (String(data.lastDate) < moment().format("DD")) {
				data.isExpired = true;
			}
		}
		setState(data);
	}, [props.data]);

	const handleClickPay = (e: any) => {
		e.stopPropagation();
		props.handleClickPay(state._id || "");
	};
	const handleClickUpdate = () => {
		setShowMoreOptions(false);
		props.handleEditItem();
	};
	const handleClickDelete = () => {
		setShowMoreOptions(false);
		props.handleDeleteItem();
	};

	return (
		<React.Fragment>
			<div className="position-relative">
				<div id={state._id} className="remainder-card" onClick={() => props.handleClickEdit(state._id || "")}>
					<div className="category_icon m-auto">
						<img height={"30px"} width={"30px"} src={getAccountIcon(state.title, iconsList.bill)} alt={state.category} />
					</div>
					<div className="w-100">
						<div className="d-flex justify-content-between">
							<div className="fntSz14 expense_title">
								{state.title}
								{state.status === "inactive" && <DoNotDisturbIcon className="account_verified_icon text-muted" />}
							</div>
							<div className="d-flex">
								<HideComponentWrapper show={!state.isPaid && state.status === "active"}>
									<img onClick={handleClickPay} src="/icons/pay-per-click.png" alt="pay" className="remainder_pay_icon" />
								</HideComponentWrapper>
								<div className="fntSz15 expense_amount font-medium">{showAmount(state.amount || 0)}</div>
							</div>
						</div>
						<div className="d-flex justify-content-between">
							<HideComponentWrapper show={state.isPaid}>
								<div className="fntSz11 bill_paid_text">
									<VerifiedIcon
										className={`bill_paid_icon ${state.billingCycle === "monthly" ? "text-success" : "primary-color"}`}
									/>
									<span className="text-success">Paid on {moment(state.billingDate).format("ddd, DD MMM")}</span>
								</div>
							</HideComponentWrapper>
							<HideComponentWrapper show={state.isExpired}>
								<div className="fntSz11 text-danger">Expired on {moment(state.lastDate, "DD").format("ddd, DD MMM")}</div>
							</HideComponentWrapper>
							<HideComponentWrapper show={!state.isPaid && !state.isExpired && state.status === "active"}>
								<div className="fntSz11 text-danger">Due on {moment(state.lastDate, "DD").format("ddd, DD MMM")}</div>
							</HideComponentWrapper>
							<HideComponentWrapper show={state.status === "inactive"}>
								<div className="fntSz11 text-smoke">Dues on {state.lastDate} of every month.</div>
							</HideComponentWrapper>

							<div className="d-flex">
								<div className="text-smoke fntSz10 mr-1">By</div>
								<div className="expense_account_icon">
									<img src={getAccountIcon(state.account, iconsList.bank)} alt={state.account} className="" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<IconButton onClick={() => setShowMoreOptions(true)} className="expense-edit-icon bg-secondary">
					{/* <img src={"/icons/edit.png"} alt={state.account} className="" /> */}
					<SettingsOutlinedIcon className="text-muted" />
				</IconButton>
			</div>
			<DrawerWrapper open={!!showMoreOptions} handleClose={() => setShowMoreOptions(false)} enableBackdropClick={true}>
				<div className="drawer-cont vh-30">
					<ul className="profile_more_opts">
						<li onClick={handleClickUpdate}>
							<IconButton>
								<EditOutlinedIcon />
							</IconButton>
							<span>Update remainder details.</span>
						</li>
						<li onClick={handleClickDelete}>
							<IconButton>
								<DeleteOutlineOutlinedIcon />
							</IconButton>
							<span>Delete this remainder</span>
						</li>
					</ul>
				</div>
			</DrawerWrapper>
		</React.Fragment>
	);
};

export default RemainderCard;
