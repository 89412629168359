import { IconButton } from "@mui/material";
import React from "react";
import { useHistory } from "react-router";
import { iconsList } from "../utils/constant";
import { getAccountIcon, getExpenseIcon, showAmount } from "../utils/functions";
import { ISpend, RoutesEnum } from "../utils/types";
interface IExpenseCard extends ISpend {
	handleClickEdit: (id: string) => void;
}

const ExpenseCard = (props: IExpenseCard) => {
	const history = useHistory();

	const handleClickEdit = () => {
		history.push(RoutesEnum.editSpend, {
			_id: props._id,
			title: props.title,
			category: props.category,
			expenseDate: props.expenseDate,
			amount: props.amount,
			account: props.account,
			createdAt: props.createdAt
		});
	};
	const IconComp = () => {
		const iconClass = getExpenseIcon(props.title);
		if (iconClass) {
			return (
				<div className="ph-icon-cont">
					<i className={iconClass} />
				</div>
			);
		}
		return <img height={"30px"} width={"30px"} src={getAccountIcon(props.title, iconsList.bill)} alt={"FMT"} />;
	};
	return (
		<React.Fragment>
			<div className="position-relative">
				<div id={props._id} onClick={() => props.handleClickEdit(props._id)} className="expense-card">
					<div className="category_icon">
						<IconComp />
					</div>
					<div className="w-100">
						<div className="d-flex justify-content-between">
							<div className="fntSz14 expense_title">{props.title}</div>
							<div className="fntSz15 expense_amount font-medium">{showAmount(props.amount)}</div>
						</div>
						<div className="d-flex justify-content-between">
							<div className="text-smoke fntSz10">For {props.category}</div>
							<div className="d-flex">
								<div className="text-smoke fntSz10 mr-2">From</div>
								<div className="expense_account_icon">
									<img src={iconsList[props.account] || iconsList.cash} alt={props.account} className="" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<IconButton onClick={handleClickEdit} className="expense-edit-icon bg-secondary">
					<img src={"/icons/edit.png"} alt={props.account} className="" />
				</IconButton>
			</div>
		</React.Fragment>
	);
};

export default ExpenseCard;
