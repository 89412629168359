import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import LoaderHoc from "./hoc/LoaderHoc";
import "./index.css";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import { detectMobileDevice } from "./utils/functions";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const isMobileView = detectMobileDevice();
document.body.classList.add(isMobileView ? "mv" : "dv");

root.render(
	<Provider store={store}>
		<SnackbarProvider anchorOrigin={{ horizontal: "right", vertical: "top" }} maxSnack={3}>
			<App />
		</SnackbarProvider>
		<LoaderHoc />
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
