import React from "react";
import LabelBottomNavigation from "../components/Footer";
import Header from "../components/Header";
type ILayoutProps = {
	activeNav?: string;
	children: React.ReactNode;
	hideHeader?: boolean;
};
const Layout = (props: ILayoutProps) => {
	return (
		<div className="layout">
			{!props.hideHeader ? <Header activeNav={props.activeNav} /> : <></>}
			<section className="body">{props.children}</section>
			<footer className="box-shadow">
				<LabelBottomNavigation />
			</footer>
		</div>
	);
};

export default Layout;
