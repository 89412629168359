import moment from "moment";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ConnectedProps, connect } from "react-redux";
import { getExpenseAnalyticsByType } from "../../redux/action";
import { ReduxStore } from "../../redux/reducer";
import { formatIndianCurrency, formatText, showAmount } from "../../utils/functions";

const MapState = (State: ReduxStore) => ({ ...State.State });
const MapDispatch = {
	getExpenseAnalyticsByType: getExpenseAnalyticsByType
};
const connector = connect(MapState, MapDispatch);
type PropTypes = ConnectedProps<typeof connector>;
interface Props extends PropTypes {
	type: any;
}

const CategoryColumns = (props: Props) => {
	const [loader, setLoader] = useState(false);

	const [series, setSeries] = useState<any>([]);
	const [options, setOptions] = useState<any>({
		chart: {
			type: "bar"
		},
		plotOptions: {
			bar: {
				barHeight: "100%",
				distributed: true,
				horizontal: true,
				dataLabels: {
					position: "bottom"
				}
			},
			colors: ["#33b2df", "#546E7A", "#d4526e", "#13d8aa", "#A5978B", "#2b908f", "#f9a3a4", "#90ee7e", "#f48024", "#69d2e7"]
		},
		dataLabels: {
			enabled: true,
			textAnchor: "start",
			style: {
				colors: ["#fff"]
			},
			formatter: function (val: any, opt: any) {
				return formatText(opt.w.globals.labels[opt.dataPointIndex]) + ":  " + showAmount(val);
			},
			offsetX: 0,
			dropShadow: {
				enabled: true
			}
		},
		xaxis: {
			categories: [],
			labels: {
				formatter: function (val: any, opts: any) {
					return formatIndianCurrency(val);
				}
			}
		},
		yaxis: {
			labels: {
				show: false
			}
		},

		legend: {
			show: false
		},
		title: {
			text: "This month expense by categories"
		},
		tooltip: {
			theme: "dark",
			x: {
				show: false
			},
			y: {
				show: false,
				title: {
					formatter: function (val: any, opt: any) {
						return formatText(opt.w.globals.labels[opt.dataPointIndex]) + ": ";
					}
				}
			}
		},

		responsive: [
			{
				breakpoint: 480,
				options: {
					chart: {
						// width: 200,
					},
					legend: {
						position: "bottom"
					}
				}
			}
		]
	});

	useEffect(() => {
		let startDate = moment().startOf("month").format("YYYY-MM-DD");
		let endDate = moment().add(1, "day").format("YYYY-MM-DD");
		setLoader(true);
		props
			.getExpenseAnalyticsByType({ startDate, endDate, type: props.type || "category" })
			.then((_res) => {
				setSeries([{ data: _res?.series }]);
				const opts = {
					...options,
					xaxis: {
						categories: _res?.labels
					}
				};
				setOptions(opts);
			})
			.finally(() => {
				setLoader(false);
			});
	}, [props.type]);

	return (
		<div id="chart">
			{loader ? (
				<div className="chart-loader">
					<div className="loader_cont">
						<div className="loader"></div>
					</div>
				</div>
			) : (
				<></>
			)}
			<ReactApexChart options={options} series={series} type="bar" />
		</div>
	);
};

export default connector(CategoryColumns);
