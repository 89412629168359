import React, { useEffect, useState } from "react";
import Layout from "../Layout";

import ExpensePieChart from "./ExpensePieChart";
import { ConnectedProps, connect } from "react-redux";
import { ReduxStore } from "../../redux/reducer";
import { getExpenseAnalytics, getExpenseAnalyticsByType, getExpenseYearlyComparision } from "../../redux/action";
import CategoryColumns from "./CategoryColumns";
import YearlyComparision from "./YearlyComparision";
import SelectInput from "../../components/input-field/SelectInput";
import { analyticsFilterTypeOptions } from "../../utils/constant";
import { ISelectInput } from "../../utils/types";
const MapState = (State: ReduxStore) => ({ ...State.State });
const MapDispatch = {
	getExpenseAnalytics: getExpenseAnalytics,
	getExpenseYearlyComparision: getExpenseYearlyComparision,
	getExpenseAnalyticsByType: getExpenseAnalyticsByType
};
const connector = connect(MapState, MapDispatch);
type PropTypes = ConnectedProps<typeof connector>;
interface Props extends PropTypes {}

const Analytics = (props: Props) => {
	const [type, setType] = useState<ISelectInput>(analyticsFilterTypeOptions[0]);
	useEffect(() => {
		// props.getExpenseAnalytics({});
		// props.getExpenseYearlyComparision({});
		// props.getExpenseAnalyticsByType({})
	}, []);
	return (
		<Layout>
			<div className="analytics mt-3 container">
				<div className="shadow-card">
					<ExpensePieChart />
				</div>
				<br />
				<div className="shadow-card d-flex  justify-content-between mb-3">
					<b className="title m-0 ">Expense Analysis</b>
					<SelectInput
						options={analyticsFilterTypeOptions}
						value={type}
						onChange={setType}
						label=""
						required={true}
						id="category"
						name="category"
						placeholder="View For"
						formClass="m-0"
					/>
				</div>
				<div className="shadow-card">
					<CategoryColumns type={type?.value} />
				</div>
				<br />
				<div className="shadow-card">
					<YearlyComparision />
				</div>
			</div>
		</Layout>
	);
};

export default connector(Analytics);
