import TuneIcon from "@mui/icons-material/Tune";
import { IconButton } from "@mui/material";
import React from "react";
import { useHistory } from "react-router";
import ProfileAvatar from "../components/ProfileAvatar";
import { addRemainderSubject, filterSubject } from "../utils/constant";
import { RoutesEnum } from "../utils/types";
import HideComponentWrapper from "./HideComponentWrapper";
type IHeaderProps = {
	activeNav?: string;
};
const Header = (props: IHeaderProps) => {
	const history = useHistory();
	const { activeNav } = props;

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {};

	const handleClickFilter = () => {
		filterSubject.next({
			showFilter: true,
			page: "spends"
		});
	};
	const handleClickAddExpense = () => {
		history.push(RoutesEnum.addExpense);
	};
	const handleClickAddRemainder = () => {
		addRemainderSubject.next(true);
	};
	return (
		<header className="d-flex container align-items-center justify-content-between ">
			<ProfileAvatar
				onClick={handleClick}
				src_text={"P"}
				src={"/images/logo.png"}
				className={""}
				id={"Profile"}
				height={"40px"}
				width={"40px"}
			/>
			<div className="">
				<i className="fas fa-glass-milk"></i>
				<HideComponentWrapper show={activeNav === RoutesEnum.home}>
					<button onClick={handleClickAddExpense} className="btn btn-sm btn-primary rounded">
						Add Expense
					</button>
				</HideComponentWrapper>
				<HideComponentWrapper show={activeNav === RoutesEnum.remainders}>
					<button onClick={handleClickAddRemainder} className="btn btn-sm btn-primary rounded">
						Add Remainder
					</button>
				</HideComponentWrapper>

				<HideComponentWrapper show={history.location.pathname === "/"}>
					<IconButton onClick={handleClickFilter} className="ml-2">
						<TuneIcon className="primary-text-color fntSz28" />
					</IconButton>
				</HideComponentWrapper>
			</div>
		</header>
	);
};

export default Header;
