import { IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DrawerWrapper from "../../components/drawer/DrawerWrapper";
import InputField from "../../components/input-field/InputField";
import { ICategoryPayload, addNewCategoryAction, deleteCategoryAction, getAllCategoriesAction, updateCategoryAction } from "../../redux/action";
import { ReduxStore } from "../../redux/reducer";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { getAccountIcon, startLoader, stopLoader } from "../../utils/functions";
import { IAccount, ICategory } from "../../utils/types";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router";
const Categories = () => {
	const dispatch = useDispatch();
	const State = useSelector((Store: ReduxStore) => Store.State);
	const [editableCard, setEditableCard] = useState<string>("");
	const [cardSettingDrawer, setCardSettingDrawer] = useState<boolean>(false);
	const [formSubmitted, setFormSubmitted] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const popupRef = useRef<HTMLDivElement>(null);
	const [title, setTitle] = useState("");
	const [activeCard, setActiveCard] = useState<IAccount | null>();
	const history = useHistory();

	const handleAddNewAccount = () => {
		setCardSettingDrawer(true);
		setTitle("");
	};

	useEffect(() => {
		getInitialData();
	}, []);

	const getInitialData = async () => {
		startLoader();
		await dispatch(getAllCategoriesAction());
		stopLoader();
	};

	const handleClickEdit = (item: ICategory) => {
		setCardSettingDrawer(true);
		setActiveCard(item);
		setTitle(item.title);
		setFormSubmitted(false);
	};

	const handleChange = (e: any) => {
		setTitle(e.target.value);
	};
	const handleCancel = () => {
		setTitle("");
		setCardSettingDrawer(false);
		setActiveCard(null);
		setFormSubmitted(false);
	};
	const handleSubmit = async () => {
		setFormSubmitted(true);

		const payload: ICategoryPayload = {
			title: title
		};
		startLoader();
		if (activeCard?._id) {
			payload._id = activeCard?._id;
			try {
				await dispatch(updateCategoryAction(payload));
				enqueueSnackbar("Category updated successfully", { variant: "success", autoHideDuration: 1000 });
				getInitialData();
				setCardSettingDrawer(false);
				setActiveCard(null);
				setFormSubmitted(false);
			} catch (error) {
				console.error("error", error);
				enqueueSnackbar("Failed to updated category details", { variant: "error", autoHideDuration: 1000 });
				stopLoader();
			}
		} else {
			try {
				await dispatch(addNewCategoryAction(payload));
				enqueueSnackbar("Category updated successfully", { variant: "success", autoHideDuration: 1000 });
				getInitialData();
				setCardSettingDrawer(false);
				setActiveCard(null);
				setFormSubmitted(false);
			} catch (error) {
				console.error("error", error);
				enqueueSnackbar("Failed to add category details", { variant: "error", autoHideDuration: 1000 });
				stopLoader();
			}
		}
	};
	const handleClickDelete = async (item: ICategory) => {
		startLoader();
		try {
			await dispatch(deleteCategoryAction(item._id));
			enqueueSnackbar("Category deleted successfully", { variant: "success", autoHideDuration: 1000 });
			getInitialData();
		} catch (error) {
			console.error("error", error);
			enqueueSnackbar("Failed to delete category", { variant: "error", autoHideDuration: 1000 });
			stopLoader();
		}
	};
	const handleBack = () => {
		history.goBack();
	};

	return (
		<React.Fragment>
			<div ref={popupRef} className="">
				<div className="d-flex justify-content-between p-3">
					<div className="d-flex align-items-center">
						<IconButton onClick={handleBack} className="bg-secondary back-btn">
							<ArrowBackIcon />
						</IconButton>
						<h4 className="mb-2 mt-0 font-semi-bold">Categories</h4>
					</div>
					<button onClick={handleAddNewAccount} className="btn btn-primary line btn-sm rounded">
						Add New
					</button>
				</div>
				<div className="p-3">
					{State.categories.map((category) => (
						<div key={category._id} id={category._id} className="account_card d-flex">
							<div className="account_icon">
								<img src={getAccountIcon(category.title)} alt={""} />
							</div>
							<h4 className="m-0 d-flex align-items-start mt-1">{category.title}</h4>

							<div className="ml-auto">
								<IconButton onClick={() => handleClickEdit(category)} className="bg-secondary">
									<EditIcon />
								</IconButton>
								<IconButton onClick={() => handleClickDelete(category)} className="bg-secondary">
									<DeleteIcon />
								</IconButton>
							</div>
						</div>
					))}
				</div>
			</div>
			<DrawerWrapper open={cardSettingDrawer} handleClose={() => handleCancel()}>
				<div className="drawer-cont vh-50">
					<InputField
						type="text"
						name="title"
						id="title"
						label="Category Name"
						placeholder="Enter Category Name"
						value={title}
						onChange={handleChange}
						required={true}
						formSubmitted={formSubmitted}
						className={""}
					/>

					<br />
					<div className="expense-footer d-flex justify-content-between ">
						<button onClick={handleCancel} className="btn btn-secondary line px-4">
							Cancel
						</button>
						<button onClick={handleSubmit} className="btn btn-primary px-4">
							Save
						</button>
					</div>
				</div>
			</DrawerWrapper>
		</React.Fragment>
	);
};

export default Categories;
