import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import { useHistory } from "react-router";
import InputField from "../components/input-field/InputField";
import { addNewCreditAction, loginAction } from "../redux/action";
import { ReduxStore } from "../redux/reducer";
import { startLoader, stopLoader } from "../utils/functions";
import { IAnyObject, ILoginPayload } from "../utils/types";
const MapState = (State: ReduxStore) => ({ ...State.State });
const MapDispatch = {
	loginAction: loginAction,
	addNewCreditAction: addNewCreditAction
};
const connector = connect(MapState, MapDispatch);
type PropTypes = ConnectedProps<typeof connector>;
interface Props extends PropTypes {}

const LoginPage = (props: Props) => {
	const history = useHistory();
	const [state, setState] = useState<ILoginPayload | IAnyObject>({});
	const [formSubmitted, setFormSubmitted] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {}, []);

	const handleChange = (e: any) => {
		let key = e.target.name;
		let val = e.target.value;
		setState({ ...state, [key]: val });
	};
	const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		setFormSubmitted(true);
		if (state.email && state.password) {
			startLoader();
			const payload: ILoginPayload = {
				email: state.email,
				password: state.password
			};
			props
				.loginAction(payload)
				.then((_res: any) => {
					stopLoader();
					history.push("/");
				})
				.catch((err: any) => {
					stopLoader();
					console.error("error", err);
					enqueueSnackbar("Failed to login", { variant: "error", autoHideDuration: 2000 });
				});
		} else {
			enqueueSnackbar("All fields are required.", { variant: "warning", autoHideDuration: 1000 });
			console.log("All fields are required.");
		}
	};

	return (
		<React.Fragment>
			<div id="login-page" className="layout login-page">
				<section className="">
					<div className="top_bg">
						<div className="login_top mb-auto d-flex">
							<div className="login_banner m-auto">
								<img src={"/images/login_banner.jpeg"} alt={"login page banner"} />
							</div>
						</div>
					</div>
					<div className="bottom_bg">
						<div className="login-container">
							<span className="slider_gate"></span>
							<form className="login-form-cont">
								<InputField
									type="email"
									name="email"
									id="email"
									label="Email"
									value={state?.email || ""}
									required={true}
									onChange={handleChange}
									placeholder="Enter email"
									className={""}
									inputMode={"email"}
									formSubmitted={formSubmitted}
								/>
								<InputField
									type="password"
									name="password"
									id="password"
									label="Password"
									value={state?.password || ""}
									required={true}
									onChange={handleChange}
									placeholder="********"
									className={""}
									inputMode={"password"}
									formSubmitted={formSubmitted}
								/>
								<br />
								<div className="login-footer">
									<button type="submit" onClick={handleSubmit} className="btn px-4">
										Login
									</button>
								</div>
							</form>
						</div>
					</div>
					{/*  */}
				</section>
			</div>
			<style>{`
            .body {
				background-color: var(--bg-secondary);
			}
        `}</style>
		</React.Fragment>
	);
};

export default connector(LoginPage);
