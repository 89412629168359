import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ConnectedProps, connect } from "react-redux";
import { ReduxStore } from "../../redux/reducer";
import { getExpenseAnalytics, getExpenseAnalyticsByType } from "../../redux/action";
import moment from "moment";
import { formatIndianCurrency, showAmount } from "../../utils/functions";

const MapState = (State: ReduxStore) => ({ ...State.State });
const MapDispatch = {
	getExpenseAnalyticsByType: getExpenseAnalyticsByType
};
const connector = connect(MapState, MapDispatch);
type PropTypes = ConnectedProps<typeof connector>;
interface Props extends PropTypes {}

const ExpensePieChart = (props: Props) => {
	const [loader, setLoader] = useState(false);
	const [series, setSeries] = useState<any>([]);
	const [options, setOptions] = useState<any>({
		chart: {
			type: "pie",
			dataLabels: {
				enabled: false
			}
		},
		labels: [],
		legend: {
			formatter: function (val: any, opts: any) {
				return val + ": " + showAmount(opts.w.globals.series[opts.seriesIndex] || 0);
			}
		},

		dataLabels: {
			enabled: false
		},
		title: {
			text: "This month expense"
		},
		tooltip: {
			y: {
				formatter: function (val: any) {
					return showAmount(val);
				}
			}
		},

		responsive: [
			{
				breakpoint: 480,
				options: {
					chart: {
						// width: 200
					},
					legend: {
						position: "bottom"
					}
				}
			}
		]
	});

	useEffect(() => {
		let startDate = moment().startOf("month").format("YYYY-MM-DD");
		let endDate = moment().add(1, "day").format("YYYY-MM-DD");
		setLoader(true);
		props
			.getExpenseAnalyticsByType({ startDate, endDate, type: "category" })
			.then((_res) => {
				setSeries(_res?.series);
				const opts = {
					...options,
					labels: _res?.labels
				};
				setOptions(opts);
			})
			.finally(() => {
				setLoader(false);
			});
	}, []);

	return (
		<div id="chart">
			{loader ? (
				<div className="chart-loader">
					<div className="loader_cont">
						<div className="loader"></div>
					</div>
				</div>
			) : (
				<></>
			)}
			<ReactApexChart options={options} series={series} type="pie" />
		</div>
	);
};

export default connector(ExpensePieChart);
