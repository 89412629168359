import moment from "moment";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ConnectedProps, connect } from "react-redux";
import { getExpenseYearlyComparision } from "../../redux/action";
import { ReduxStore } from "../../redux/reducer";
import { formatIndianCurrency } from "../../utils/functions";

const MapState = (State: ReduxStore) => ({ ...State.State });
const MapDispatch = {
	getExpenseYearlyComparision: getExpenseYearlyComparision
};
const connector = connect(MapState, MapDispatch);
type PropTypes = ConnectedProps<typeof connector>;
interface Props extends PropTypes {}

const YearlyComparision = (props: Props) => {
	const [loader, setLoader] = useState(false);

	const [series, setSeries] = useState<any>([]);
	const [options, setOptions] = useState<any>({
		chart: {
			type: "bar",
			height: 600
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: "85%",
				endingShape: "rounded"
			},
			colors: ["#33b2df", "#546E7A", "#d4526e", "#13d8aa", "#A5978B", "#2b908f", "#f9a3a4", "#90ee7e", "#f48024", "#69d2e7"]
		},
		dataLabels: {
			enabled: false
		},
		stroke: {
			show: true,
			width: 2,
			colors: ["transparent"]
		},
		xaxis: {
			categories: []
		},
		yaxis: {
			labels: {
				formatter: function (val: any, opts: any) {
					return formatIndianCurrency(val);
				}
			}
		},
		fill: {
			opacity: 1
		},
		// legend: {
		// 	show: false
		// },
		title: {
			text: "This Year Expenses"
		},
		tooltip: {
			theme: "dark",
			y: {
				formatter: function (val: any) {
					return val?.toLocaleString("en-IN");
				}
			}
		},

		responsive: [
			{
				breakpoint: 480,
				options: {
					chart: {
						// width: 200,
					},
					legend: {
						position: "bottom"
					}
				}
			}
		]
	});

	useEffect(() => {
		setLoader(true);
		let startDate = moment().year();
		props
			.getExpenseYearlyComparision({ year: String(startDate) })
			.then((_res) => {
				setSeries([_res.series[1]]);

				const opts = {
					...options,
					xaxis: {
						categories: _res?.categories
					}
				};
				setOptions(opts);
			})
			.finally(() => {
				setLoader(false);
			});
	}, []);

	return (
		<div id="chart">
			{loader ? (
				<div className="chart-loader">
					<div className="loader_cont">
						<div className="loader"></div>
					</div>
				</div>
			) : (
				<></>
			)}
			<ReactApexChart options={options} series={series} type="bar" height={500} />
		</div>
	);
};

export default connector(YearlyComparision);
