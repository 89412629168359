import moment from "moment";
import { useEffect, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import ExpenseCard from "../components/ExpenseCard";
import ExpenseFilter from "../components/drawer/ExpenseFilter";
import { getAllAccountsAction, getAllCategoriesAction, getAllExpenseAction, getSpendCalculationAction } from "../redux/action";
import { ReduxStore } from "../redux/reducer";
import { getGreetingMessage, showAmount, startLoader, stopLoader, transactionTime } from "../utils/functions";
import Layout from "./Layout";
import { filterSubject } from "../utils/constant";
import { IExpenseFilters, RoutesEnum } from "../utils/types";
import { getCookieObj, setCookie, setCookieObj } from "../utils/cookie";

const MapState = (State: ReduxStore) => ({ ...State.State });
const MapDispatch = {
	getAllExpenseAction: getAllExpenseAction,
	getSpendCalculationAction: getSpendCalculationAction,
	getAllCategoriesAction: getAllCategoriesAction,
	getAllAccountsAction: getAllAccountsAction
};
const connector = connect(MapState, MapDispatch);
type PropTypes = ConnectedProps<typeof connector>;
interface Props extends PropTypes {}

const Homepage = (props: Props) => {
	const [editItem, setEditItem] = useState<string>("");
	const [showFilters, setShowFilters] = useState<boolean>(false);

	useEffect(() => {
		getInitialData();
		filterSubject.subscribe({
			next: (item: any) => {
				if (item?.showFilter && item?.page === "spends") {
					setShowFilters(true);
				}
			}
		});
	}, []);
	const getInitialData = () => {
		const filters = getCookieObj("filters");
		const today = moment().add(1, "day").format("YYYY-MM-DD");
		const monthStart = moment().startOf("month").format("YYYY-MM-DD");
		if (!filters?.startDate) {
			filters.startDate = monthStart;
			filters.endDate = today;
			setCookieObj("filters", filters);
		}
		if (!Object.keys(props.spends).length) {
			startLoader();
		}
		props.getAllExpenseAction(filters).finally(() => {
			stopLoader();
		});
		props.getSpendCalculationAction(filters);
		if (!props.categories?.length) {
			props.getAllCategoriesAction();
		}
		if (!props.accounts?.length) {
			props.getAllAccountsAction();
		}
	};

	const handleEditExpense = (id: string) => {
		if (editItem && editItem !== id) {
			document.getElementById(editItem)?.classList.toggle("editable_card_item");
			setEditItem(id);
			document.getElementById(id)?.classList.toggle("editable_card_item");
		} else if (editItem && editItem === id) {
			document.getElementById(editItem)?.classList.toggle("editable_card_item");
			setEditItem("");
		} else {
			setEditItem(id);
			document.getElementById(id)?.classList.toggle("editable_card_item");
		}
	};

	const handleFilter = (filters: IExpenseFilters) => {
		startLoader();
		setShowFilters(false);
		props.getSpendCalculationAction(filters);
		props.getAllExpenseAction(filters).finally(() => {
			stopLoader();
		});
	};

	return (
		<Layout activeNav={RoutesEnum.home}>
			<div className="container pt-3">
				<section className="d-flex justify-content-between summary_bar">
					<div className="">
						<div className="fntSz12">{getGreetingMessage()},</div>
						<div className="fntSz20 font-bold primary-color">{props.profile.name || "Jagan"}</div>
					</div>
					<div className="">
						<div className="fntSz12 text-muted">{"This Month"} Spends</div>
						<div className="fnrSz16 text-link text-right font-medium">{showAmount(props.totalSpendAmount)}</div>
					</div>
				</section>
				<section>
					{Object.keys(props.spends).map((date) => (
						<div key={date} className="">
							<div className="font-medium mb-2 mt-3">{transactionTime(date)}</div>
							<div className="spendings_list">
								{props.spends[date].map((spend, index) => (
									<ExpenseCard key={index} {...spend} handleClickEdit={handleEditExpense} />
								))}
							</div>
						</div>
					))}
				</section>
				<br />
			</div>
			<ExpenseFilter open={showFilters} onClose={() => setShowFilters(false)} handleApply={handleFilter} page="spends" />
		</Layout>
	);
};

export default connector(Homepage);
