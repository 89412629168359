import AddCardIcon from "@mui/icons-material/AddCard";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import { IconButton } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { ConnectedProps, connect } from "react-redux";
import { useHistory } from "react-router";
import LabelBottomNavigation from "../../components/Footer";
import DrawerWrapper from "../../components/drawer/DrawerWrapper";
import { getProfileDataAction, updateProfileDataAction } from "../../redux/action";
import { ReduxStore } from "../../redux/reducer";
import { iconsList } from "../../utils/constant";
import { clearSession } from "../../utils/cookie";
import { showAmount, startLoader, stopLoader } from "../../utils/functions";
import { RoutesEnum } from "../../utils/types";
import BankAccounts from "./BankAccounts";
const MapState = (State: ReduxStore) => ({ ...State.State });
const MapDispatch = {
	getProfileDataAction: getProfileDataAction,
	updateProfileDataAction: updateProfileDataAction
};
const connector = connect(MapState, MapDispatch);
type PropTypes = ConnectedProps<typeof connector>;
interface Props extends PropTypes {}

const Profile = (props: Props) => {
	const accountsRef = useRef<any>();
	const history = useHistory();
	const [showMoreOptions, setShowMoreOptions] = React.useState(false);

	useEffect(() => {
		startLoader();
		props.getProfileDataAction().then(stopLoader).catch(stopLoader);
	}, []);
	const handleClickAddExpense = () => {
		history.push(RoutesEnum.addExpense);
	};

	const handleAddNewCard = () => {
		console.log("accountsRef.current", accountsRef);
		setShowMoreOptions(false);
		if (accountsRef.current) {
			accountsRef.current.handleAddNewAccount();
		}
	};

	const handleLogout = () => {
		clearSession();
		window.open("/login", "_self");
	};

	const handleClickCategories = () => {
		history.push(RoutesEnum.categories);
	};
	return (
		<React.Fragment>
			<div id="profile" className="layout">
				<section className="body">
					<div className="top_bg">
						<div className="profile_card mb-auto d-flex">
							<div className="profile_icon">
								<img
									src={props.profile?.profilePic || iconsList["food"]}
									onError={(e: any) => {
										e.target.src = iconsList["food"];
									}}
									alt={props.profile.name || "Jagan"}
								/>
							</div>
							<span className="profile_name font-semi-bold">Mr. {props.profile.name || "Jagan"}</span>
							<div className="right-icons ml-auto">
								<IconButton onClick={() => setShowMoreOptions(true)}>
									<SettingsTwoToneIcon className="setting_icon primary-color" />
								</IconButton>
							</div>
						</div>
						<div className="total_bal_card d-flex justify-content-center mx-3">
							<div className="text-center">
								<label>Avalilable Balance</label>
								<h2 className="my-2 font-medium">{showAmount(props.profile.totalAccountBalance)}</h2>
							</div>
						</div>
					</div>
					<div className="med_bg">
						<div className=" mt-4">
							<div className="d-flex justify-content-between gap-3">
								<div className="balance-card income">
									<img className="mb-3" height={"40px"} width={"40px"} src="/icons/spending.png" alt={"income.png"} />
									<br />
									<p className="m-0 fntSz12 text-muted font-regular">This Month Expense</p>
									<h4 className="mb-0 mt-1 font-medium">{showAmount(props.profile.totalCredits)}</h4>
								</div>
								<div className="balance-card expense">
									<img className="mb-3" height={"40px"} width={"40px"} src="/icons/spending.png" alt={"income.png"} />
									<br />
									<p className="m-0 fntSz12 text-muted font-regular">This Year Expense</p>
									<h4 className="mb-0 mt-1 font-medium">{showAmount(props.profile.totalSpends)}</h4>
								</div>
							</div>
						</div>
						<div className="d-flex gap-1 justify-content-between mt-4">
							<button onClick={handleClickAddExpense} className="btn btn-primary rounded">
								+ Add expense
							</button>
							<IconButton onClick={() => setShowMoreOptions(true)} className="btn-more-icon">
								<MoreHorizIcon />
							</IconButton>
						</div>
					</div>
					<BankAccounts />
					<br />
					<br />
					<br />
				</section>
				<footer className="box-shadow">
					<LabelBottomNavigation />
				</footer>
				<DrawerWrapper open={!!showMoreOptions} handleClose={() => setShowMoreOptions(false)} enableBackdropClick={true}>
					<div className="drawer-cont vh-30">
						<ul className="profile_more_opts">
							<li onClick={handleAddNewCard}>
								<IconButton>
									<AddCardIcon />
								</IconButton>
								<span>Add New Account</span>
							</li>
							<li onClick={handleClickCategories}>
								<IconButton>
									<AddCardIcon />
								</IconButton>
								<span>Categories</span>
							</li>

							<li aria-disabled onClick={() => {}}>
								<IconButton>
									<PermIdentityOutlinedIcon />
								</IconButton>
								<span>Profile Settings</span>
							</li>
							<li onClick={handleLogout}>
								<IconButton>
									<ExitToAppOutlinedIcon />
								</IconButton>
								<span>Logout</span>
							</li>
						</ul>
					</div>
				</DrawerWrapper>
			</div>
			<style>{`
            // .top_bg{
            //     background-image: url("/images/image_2.jpeg");
            // }
        `}</style>
		</React.Fragment>
	);
};

export default connector(Profile);
