import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import React from "react";
import { useHistory } from "react-router";
import { parseParam } from "../utils/functions";
import { RoutesEnum } from "../utils/types";
import { IconButton } from "@mui/material";
export default function LabelBottomNavigation() {
	const history = useHistory();
	const params = parseParam(history.location.pathname);
	const [value, setValue] = React.useState(`/${params[0]}`);
	const handleChange = (event: any, newValue: any) => {
		setValue(newValue);
		history.push(newValue);
	};

	return (
		<BottomNavigation className="bottom-nav" value={value} onChange={handleChange}>
			<BottomNavigationAction
				value={RoutesEnum.home}
				icon={
					<IconButton className="bottom-nav-icon-cont">
						<HomeOutlinedIcon className="bottom-nav-icon" />
					</IconButton>
				}
			/>
			<BottomNavigationAction
				value={RoutesEnum.remainders}
				icon={
					<IconButton className="bottom-nav-icon-cont">
						<ListAltOutlinedIcon className="bottom-nav-icon" />
					</IconButton>
				}
			/>
			<BottomNavigationAction
				value={RoutesEnum.analytics}
				icon={
					<IconButton className="bottom-nav-icon-cont">
						<AppsOutlinedIcon className="bottom-nav-icon" />
					</IconButton>
				}
			/>
			<BottomNavigationAction
				value={RoutesEnum.profile}
				icon={
					<IconButton className="bottom-nav-icon-cont">
						<AccountCircleOutlinedIcon className="bottom-nav-icon" />
					</IconButton>
				}
			/>
		</BottomNavigation>
	);
}
