import CancelIcon from "@mui/icons-material/Cancel";
import { FormControlLabel, IconButton, Radio, RadioGroup, Switch } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import HideComponentWrapper from "../../components/HideComponentWrapper";
import DrawerWrapper from "../../components/drawer/DrawerWrapper";
import DateInput from "../../components/input-field/DateInput";
import InputField from "../../components/input-field/InputField";
import SelectInput from "../../components/input-field/SelectInput";
import { IRemainderPayload, addNewRemainderAction, deleteRemainderAction, getRemaindersAction, updateRemainderAction } from "../../redux/action";
import { ReduxStore } from "../../redux/reducer";
import { addRemainderSubject } from "../../utils/constant";
import { startLoader, stopLoader } from "../../utils/functions";
import { IAnyObject, RoutesEnum } from "../../utils/types";
import Layout from "../Layout";
import RemainderCard from "./RemainderCard";

const MapState = (State: ReduxStore) => ({ ...State.State });
const MapDispatch = {
	getRemaindersAction: getRemaindersAction,
	addNewRemainderAction: addNewRemainderAction,
	updateRemainderAction: updateRemainderAction,
	deleteRemainderAction: deleteRemainderAction
};
const connector = connect(MapState, MapDispatch);
type PropTypes = ConnectedProps<typeof connector>;
interface Props extends PropTypes {}

const Remainders = (props: Props) => {
	const [editItem, setEditItem] = useState<string>("");
	const [open, setOpen] = useState<boolean>(false);
	const [state, setState] = useState<IAnyObject>({});
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [openPayDialog, setOpenPayDialog] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (!props.remainders.length) {
			startLoader();
		}
		props.getRemaindersAction().finally(() => {
			stopLoader();
		});
		addRemainderSubject.subscribe(() => setOpen(true));
	}, []);

	useEffect(() => {
		if (open) {
			if (editItem) {
				let data = props.remainders.find((item) => item._id === editItem) || {};
				console.log("billing date:", data.billingDate, moment(data.billingDate));
				const payload: any = {
					title: data.title,
					amount: data.amount,
					billingCycle: data.billingCycle,
					billingDate: data.billingDate,
					lastDate: moment(data.billingDate).format("YYYY-MM-DD"),
					account: null,
					status: data.status
				};
				const account = props.accounts.find((acc) => acc.title === data.account);
				payload.account = {
					label: account?.title,
					value: account?.title
				};
				const category = props.categories.find((cat) => cat.title === data.category);
				payload.category = {
					label: category?.title,
					value: category?.title
				};
				setState(payload);
			}
		}
	}, [open]);

	const handleEditExpense = (id: string) => {
		if (editItem && editItem !== id) {
			document.getElementById(editItem)?.classList.toggle("editable_card_item");
			setEditItem(id);
			document.getElementById(id)?.classList.toggle("editable_card_item");
		} else if (editItem && editItem === id) {
			document.getElementById(editItem)?.classList.toggle("editable_card_item");
			setEditItem("");
		} else {
			setEditItem(id);
			document.getElementById(id)?.classList.toggle("editable_card_item");
		}
	};
	const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setFormSubmitted(true);
		if (state.title && state.account && state.amount && state.category && state.billingCycle && state.lastDate) {
			const payload: IRemainderPayload = {
				title: state.title,
				account: state.account?.value,
				category: state.category?.value,
				amount: state.amount,
				billingCycle: state.billingCycle,
				lastDate: moment(state.lastDate).format("DD"),
				billingDate: moment(state.lastDate).format("YYYY-MM-DD"),
				status: state.status || "active"
			};
			let ApiPromise = new Promise((resolve) => resolve);
			if (editItem) {
				ApiPromise = props.updateRemainderAction(editItem, payload);
			} else {
				ApiPromise = props.addNewRemainderAction(payload);
			}
			startLoader();
			ApiPromise.then(props.getRemaindersAction)
				.then((_res: any) => {
					stopLoader();
					enqueueSnackbar(` ${editItem ? state.title + "updated" : "New bill added"} successfully.`, {
						variant: "success",
						autoHideDuration: 1000
					});
					handleClose();
				})
				.catch((err: any) => {
					stopLoader();
					console.error("error", err);
					enqueueSnackbar(`Failed to ${editItem ? "update" + state.title : "add new bill"}.`, { variant: "error", autoHideDuration: 2000 });
				});
		} else {
			enqueueSnackbar("All fields are required.", { variant: "warning", autoHideDuration: 1000 });
		}
	};
	const handleChange = (e: any) => {
		let key = e.target.name;
		let val = e.target.value;
		setState({ ...state, [key]: val });
	};
	const handleSelect = (event: any, name: string) => {
		setState({ ...state, [name]: event });
	};
	const handleSelectDate = (date: any, name: string) => {
		setState({ ...state, [name]: date });
	};

	const handleClose = () => {
		setOpen(false);
		setState({});
		setFormSubmitted(false);
		if (editItem) {
			handleEditExpense(editItem);
		}
	};
	const handleEditItem = () => {
		setOpen(true);
	};
	const handleDeleteItem = () => {
		startLoader();
		props.deleteRemainderAction(editItem).finally(() => {
			stopLoader();
			props.getRemaindersAction();
			enqueueSnackbar("Bill deleted successfully.", { variant: "success", autoHideDuration: 1000 });
			handleClose();
		});
	};
	const handleClickPay = (id: string) => {
		let data = props.remainders.find((item) => item._id === id) || {};
		setOpenPayDialog(true);
		setState({
			_id: id,
			title: data.title,
			amount: data.amount,
			billingDate: moment().format("YYYY-MM-DD"),
			status: data.status,
			addExpense: "true"
		});
	};
	const handleSubmitPay = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setFormSubmitted(true);
		let data: any = props.remainders.find((item) => item._id === state._id) || {};

		const payload: IRemainderPayload = {
			title: data.title || "",
			account: data.account,
			category: data.category,
			amount: data.amount,
			billingCycle: data.billingCycle,
			lastDate: data.lastDate,
			status: data.status || "active",
			billingDate: moment(state.billingDate).format("YYYY-MM-DD"),
			addExpense: state.addExpense === "true" ? true : false
		};
		startLoader();
		props
			.updateRemainderAction(state._id, payload)
			.then(props.getRemaindersAction)
			.then((_res: any) => {
				stopLoader();
				enqueueSnackbar("New bill added successfully.", { variant: "success", autoHideDuration: 1000 });
				handleClosePayDialog();
			})
			.catch((err: any) => {
				stopLoader();
				console.error("error", err);
				enqueueSnackbar("Failed to new bill.", { variant: "error", autoHideDuration: 2000 });
				handleClosePayDialog();
			});
	};
	const handleClosePayDialog = () => {
		setOpenPayDialog(false);
		setState({});
		setFormSubmitted(false);
	};
	return (
		<Layout activeNav={RoutesEnum.remainders}>
			<div className="container pt-3">
				<section>
					<div className="d-flex justify-content-between mb-3 align-items-center">
						<h2 className="font-bold m-0">Remainders</h2>
						<div className="font-medium text-muted">{moment().format("MMMM YYYY")}</div>
						{/* <button onClick={() => setOpen(true)} className="btn btn-sm btn-primary rounded">
							+ Add New
						</button> */}
					</div>
					<div className="remainders_list">
						{props.remainders.map((data, index) => (
							<RemainderCard
								key={index}
								data={data}
								handleEditItem={handleEditItem}
								handleClickEdit={handleEditExpense}
								handleClickPay={handleClickPay}
								handleDeleteItem={handleDeleteItem}
							/>
						))}
					</div>
				</section>
				<br />
			</div>

			<DrawerWrapper open={open} handleClose={handleClose}>
				<HideComponentWrapper show={open}>
					<div className="drawer-cont vh-80 pt-0">
						<div className="d-flex justify-content-between drawer-header pt-3 align-items-center zIndex mb-2">
							<h3 className="text-capitalize font-medium m-0">Add New Billing</h3>
							<IconButton onClick={handleClose}>
								<CancelIcon className="text-muted" />
							</IconButton>
						</div>
						<InputField
							type="text"
							name="title"
							id="title"
							label="Billing Title"
							value={state?.title || ""}
							required={true}
							onChange={handleChange}
							placeholder="Enter Title"
							className={""}
							inputMode={"text"}
							formSubmitted={formSubmitted}
						/>
						<InputField
							type="number"
							name="amount"
							id="amount"
							label="Billing Amount"
							value={state?.amount || ""}
							required={true}
							onChange={handleChange}
							placeholder="Enter amount"
							className={""}
							inputMode={"decimal"}
							formSubmitted={formSubmitted}
						/>
						<div className="form-group mb-2">
							<div className="">Billing Cycle</div>
							<RadioGroup onChange={handleChange} row aria-labelledby="billing cycle" name="billingCycle">
								<FormControlLabel
									value="monthly"
									checked={state.billingCycle === "monthly"}
									name="billingCycle"
									control={<Radio className="billing-cycle" />}
									label="Monthly"
								/>
								<FormControlLabel
									value="yearly"
									checked={state.billingCycle === "yearly"}
									name="billingCycle"
									control={<Radio className="billing-cycle" />}
									label="Yearly"
								/>
								<FormControlLabel
									value="none"
									checked={state.billingCycle === "none"}
									name="billingCycle"
									control={<Radio className="billing-cycle" />}
									label="None"
								/>
							</RadioGroup>
						</div>
						<DateInput
							value={state.lastDate}
							onChange={handleSelectDate}
							label="Dues on?"
							required={true}
							id="lastDate"
							name="lastDate"
							placeholder="Select Date"
							formSubmitted={formSubmitted}
							format="YYYY-MM-DD"
							disalbleTime={true}
						/>
						<SelectInput
							options={props.categories.map((cat) => ({ value: cat.title, label: cat.title }))}
							value={state.category}
							onChange={handleSelect}
							label="Select Category"
							required={true}
							id="category"
							name="category"
							placeholder="Select account type"
							formSubmitted={formSubmitted}
						/>
						<SelectInput
							options={props.accounts.map((acc) => ({ value: acc.title, label: acc.title }))}
							value={state.account}
							onChange={handleSelect}
							label="Billing Account"
							required={true}
							id="account"
							name="account"
							placeholder="Select account"
							formSubmitted={formSubmitted}
						/>

						<HideComponentWrapper show={!!editItem}>
							<div className={`d-flex align-items-center justify-content-between`}>
								<label className="form-label cursor-text m-0" htmlFor={"isActive"}>
									Is Active
								</label>
								<Switch
									id="isActive"
									checked={state.status === "active" ? true : false}
									onClick={(e: any) => setState({ ...state, status: e.target.checked ? "active" : "inactive" })}
								/>
							</div>
						</HideComponentWrapper>

						<br />
						<div className="expense-filter-footer d-flex justify-content-between ">
							<button onClick={handleClose} className="btn btn-secondary line px-4">
								Cancel
							</button>
							<button onClick={handleSubmit} className="btn btn-primary px-4">
								Save
							</button>
						</div>
					</div>
				</HideComponentWrapper>
			</DrawerWrapper>
			<DrawerWrapper open={openPayDialog} handleClose={handleClosePayDialog}>
				<div className="drawer-cont vh-50 pt-0">
					<div className="d-flex justify-content-between drawer-header pt-3 align-items-center zIndex mb-2">
						<h3 className="text-capitalize font-medium m-0">Close Remainder</h3>
						<IconButton onClick={handleClosePayDialog}>
							<CancelIcon className="text-muted" />
						</IconButton>
					</div>

					<div className="form-group my-2">
						<div className="">Billing Cycle</div>
						<RadioGroup onChange={handleChange} row aria-labelledby="billing cycle" name="addExpense">
							<FormControlLabel
								value="true"
								name="addExpense"
								checked={state.addExpense === "true"}
								control={<Radio className="billing-cycle" />}
								label="Close remainder & add to Expense"
							/>
							<FormControlLabel
								value="false"
								name="addExpense"
								checked={state.addExpense === "false"}
								control={<Radio className="billing-cycle" />}
								label="Just close this remainder."
							/>
						</RadioGroup>
					</div>
					<DateInput
						value={state.billingDate}
						onChange={handleSelectDate}
						label="Paid on?"
						required={true}
						id="billingDate"
						name="billingDate"
						placeholder="Select Date"
						formSubmitted={formSubmitted}
						format="YYYY-MM-DD"
						disalbleTime={true}
					/>

					<br />
					<div className="expense-filter-footer d-flex justify-content-between ">
						<button onClick={handleClosePayDialog} className="btn btn-secondary line px-4">
							Cancel
						</button>
						<button onClick={handleSubmitPay} className="btn btn-primary px-4">
							Pay
						</button>
					</div>
				</div>
			</DrawerWrapper>
		</Layout>
	);
};

export default connector(Remainders);
