import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DrawerWrapper from "../../components/drawer/DrawerWrapper";
import InputField from "../../components/input-field/InputField";
import { addNewAccountAction, getAllAccountsAction, updateAccountAction } from "../../redux/action";
import { ReduxStore } from "../../redux/reducer";
import { getAccountIcon, startLoader, stopLoader } from "../../utils/functions";
import { IAccount } from "../../utils/types";

const BankAccounts = () => {
	const dispatch = useDispatch();
	const State = useSelector((Store: ReduxStore) => Store.State);
	const [editableCard, setEditableCard] = useState<string>("");
	const [cardSettingDrawer, setCardSettingDrawer] = useState<boolean>(false);
	const [formSubmitted, setFormSubmitted] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const popupRef = useRef<HTMLDivElement>(null);
	const [title, setTitle] = useState("");
	const [activeCard, setActiveCard] = useState<IAccount>();

	const handleAddNewAccount = () => {
		setCardSettingDrawer(true);
		setTitle("");
	};

	useEffect(() => {
		getInitialData();
	}, []);

	const getInitialData = async () => {
		startLoader();
		await dispatch(getAllAccountsAction());
		stopLoader();
	};

	const handleEditCard = (id: string) => {
		console.log("id", id);
		console.log("editableCard", editableCard);
		if (editableCard && editableCard !== id) {
			document.getElementById(editableCard)?.classList.toggle("editable_card_item");
			setEditableCard(id);
			document.getElementById(id)?.classList.toggle("editable_card_item");
		} else if (editableCard && editableCard === id) {
			document.getElementById(editableCard)?.classList.toggle("editable_card_item");
			setEditableCard("");
		} else {
			setEditableCard(id);
			document.getElementById(id)?.classList.toggle("editable_card_item");
		}
	};
	const handleClickCardSetting = (item: IAccount) => {
		setCardSettingDrawer(true);
	};

	const handleChange = (e: any) => {
		setTitle(e.target.value);
	};
	const handleCancel = () => {
		setTitle("");
		setCardSettingDrawer(false);
	};
	const handleSubmit = async () => {
		setFormSubmitted(true);

		const payload: IAccount = {
			title: title
		};
		startLoader();
		if (activeCard?._id) {
			payload._id = activeCard?._id;
			try {
				await dispatch(updateAccountAction(payload));
				enqueueSnackbar("Account updated successfully", { variant: "success", autoHideDuration: 1000 });
				getInitialData();
				setCardSettingDrawer(false);
			} catch (error) {
				console.error("error", error);
				enqueueSnackbar("Failed to updated account details", { variant: "error", autoHideDuration: 1000 });
				stopLoader();
			}
		} else {
			try {
				await dispatch(addNewAccountAction(payload));
				enqueueSnackbar("Account updated successfully", { variant: "success", autoHideDuration: 1000 });
				getInitialData();
				setCardSettingDrawer(false);
			} catch (error) {
				console.error("error", error);
				enqueueSnackbar("Failed to add account details", { variant: "error", autoHideDuration: 1000 });
				stopLoader();
			}
		}
	};

	return (
		<React.Fragment>
			<div ref={popupRef} className="bott_bg pt-3">
				<div className="d-flex justify-content-between mb-2">
					<h4 className="mb-2 mt-0 font-semi-bold">Your Accounts</h4>
					<button onClick={handleAddNewAccount} className="btn btn-primary line btn-sm rounded">
						Add New
					</button>
				</div>
				{State.accounts.map((account) => (
					<div key={account._id} id={account._id} className="account_card d-flex">
						<div className="account_icon">
							<img src={getAccountIcon(account.title)} alt={""} />
						</div>
						<h4 className="m-0 d-flex align-items-start mt-1">{account.title}</h4>

						<div className="ml-auto">
							<IconButton onClick={() => handleClickCardSetting(account)} className="bg-secondary">
								<EditIcon />
							</IconButton>
							<IconButton onClick={() => handleClickCardSetting(account)} className="bg-secondary">
								<DeleteIcon />
							</IconButton>
						</div>
					</div>
				))}
			</div>
			<DrawerWrapper open={cardSettingDrawer} handleClose={() => setCardSettingDrawer(false)}>
				<div className="drawer-cont vh-50">
					<InputField
						type="text"
						name="title"
						id="title"
						label="Account Name"
						placeholder="Enter Account Name"
						value={title}
						onChange={handleChange}
						required={true}
						formSubmitted={formSubmitted}
						className={""}
					/>

					<br />
					<div className="expense-footer d-flex justify-content-between ">
						<button onClick={handleCancel} className="btn btn-secondary line px-4">
							Cancel
						</button>
						<button onClick={handleSubmit} className="btn btn-primary px-4">
							Save
						</button>
					</div>
				</div>
			</DrawerWrapper>
		</React.Fragment>
	);
};

export default BankAccounts;
