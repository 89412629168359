import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "react-router";
import { Route, BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import { getAllAccountsAction, getAllCategoriesAction, getProfileDataAction } from "./redux/action";
import { ReduxStore } from "./redux/reducer";
import { getCookie } from "./utils/cookie";
import { RoutesEnum } from "./utils/types";
import Homepage from "./views/Homepage";
import LoginPage from "./views/LoginPage";
import SplashScreen from "./views/SplashScreen";
import Analytics from "./views/analytics/Analytics";
import AddEditExpense from "./views/profile/AddEditExpense";
import Categories from "./views/profile/Categories";
import Profile from "./views/profile/Profile";
import Remainders from "./views/remainders/Remainders";
function App() {
	const state = useSelector((Store: ReduxStore) => Store.State);
	const dispatch = useDispatch();
	const profileData = useSelector((Store: ReduxStore) => Store.State);
	const token = getCookie("token");
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
		if (!profileData.profile?.totalSpends && token) {
			dispatch(getProfileDataAction());
		}
		if (token) {
			if (!state.categories?.length) {
				dispatch(getAllCategoriesAction());
			}
			if (!state.accounts?.length) {
				dispatch(getAllAccountsAction());
			}
		}
	}, []);
	if (isLoading) {
		return <SplashScreen />;
	}
	return (
		<Router>
			<div className="App">
				<Switch>
					<Route exact path={RoutesEnum.login}>
						<LoginPage />
					</Route>
					<Route exact path={RoutesEnum.home}>
						<Homepage />
					</Route>
					<Route exact path={RoutesEnum.remainders}>
						<Remainders />
					</Route>
					<Route exact path={RoutesEnum.analytics}>
						<Analytics />
					</Route>
					<Route exact path={RoutesEnum.profile}>
						<Profile />
					</Route>

					<Route exact path={RoutesEnum.addExpense}>
						<AddEditExpense />
					</Route>
					<Route exact path={RoutesEnum.editSpend}>
						<AddEditExpense isEdit={true} />
					</Route>

					<Route exact path={RoutesEnum.remainders}>
						<Remainders />
					</Route>
					<Route exact path={RoutesEnum.categories}>
						<Categories />
					</Route>
				</Switch>
			</div>
		</Router>
	);
}

export default App;
