import React from "react";

const SplashScreen = () => {
	return (
		<div id="splash_screen">
			<img src="/splash_screen.png" alt="FMT" />
		</div>
	);
};

export default SplashScreen;
