import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import { useHistory } from "react-router";
import DateInput from "../../components/input-field/DateInput";
import InputField from "../../components/input-field/InputField";
import SelectInput from "../../components/input-field/SelectInput";
import {
	IExpensePayload,
	addNewCreditAction,
	addNewSpendAction,
	switchAmountAction,
	updateCreditAction,
	updateSpendsAction
} from "../../redux/action";
import { ReduxStore } from "../../redux/reducer";
import { iconsList } from "../../utils/constant";
import { startLoader, stopLoader } from "../../utils/functions";
import { IAccount, IAnyObject, ICategory, ISelectInput } from "../../utils/types";
const MapState = (State: ReduxStore) => ({ ...State.State });
const MapDispatch = {
	addNewSpendAction: addNewSpendAction,
	addNewCreditAction: addNewCreditAction,
	switchAmountAction: switchAmountAction,
	updateSpendsAction: updateSpendsAction,
	updateCreditAction: updateCreditAction
};
const connector = connect(MapState, MapDispatch);
type PropTypes = ConnectedProps<typeof connector>;
interface Props extends PropTypes {
	isEdit?: boolean;
}

const AddEditExpense = (props: Props) => {
	const history = useHistory();
	const [state, setState] = useState<IAnyObject>({});
	const [formSubmitted, setFormSubmitted] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const expenseDetails = history.location.state as IAnyObject;

	useEffect(() => {
		if (props.isEdit && expenseDetails?._id) {
			setState({
				title: expenseDetails.title,
				amount: expenseDetails.amount,
				expenseDate: dayjs(expenseDetails.expenseDate).format("YYYY-MM-DD HH:mm"),
				category: { value: expenseDetails.category, label: expenseDetails.category },
				account: { value: expenseDetails.account, label: expenseDetails.account }
			});
		} else {
			setState({
				...state,
				expenseDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm")
			});
		}
	}, []);

	const handleChange = (e: any) => {
		let key = e.target.name;
		let val = e.target.value;
		setState({ ...state, [key]: val });
	};
	const handleSelect = (event: any, name: string) => {
		setState({ ...state, [name]: event });
	};
	const handleSelectDate = (date: any, name: string) => {
		console.log("date", date);
		setState({ ...state, [name]: date });
	};
	const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		setFormSubmitted(true);
		if (state.title && state.amount && state.category && state.account && state.expenseDate) {
			startLoader();
			const payload: IExpensePayload = {
				title: state.title,
				amount: state.amount,
				category: state.category.value,
				account: state.account.value,
				expenseDate: state.expenseDate
			};
			let ApiPromise: Promise<any> = new Promise(() => {});
			if (props.isEdit) {
				ApiPromise = props.updateSpendsAction(payload, expenseDetails?._id);
			} else {
				ApiPromise = props.addNewSpendAction(payload);
			}
			ApiPromise.then((_res: any) => {
				stopLoader();
				enqueueSnackbar(`Expense ${props.isEdit ? "Updated" : "added"} successfully`, { variant: "success", autoHideDuration: 1000 });
				history.goBack();
			}).catch((err: any) => {
				stopLoader();
				console.error("error", err);
				enqueueSnackbar(`Failed to  ${props.isEdit ? "Updated" : "added"} expense`, { variant: "error", autoHideDuration: 2000 });
			});
		} else {
			enqueueSnackbar("Please fill all required fields.", { variant: "warning", autoHideDuration: 1000 });
			console.log("All fields are required.");
		}
	};
	const handleCancel = () => {
		history.goBack();
	};
	return (
		<React.Fragment>
			<div id="add-expense" className="layout add-expense">
				<section className="body">
					<div className="top_bg">
						<div className="profile_card mb-auto d-flex">
							<div className="profile_icon">
								<img src={iconsList["food"]} alt={"food.png"} />
							</div>
							<span className="profile_name font-semi-bold">
								{props.isEdit ? "Edit" : "Add New"} {"Expense"}
							</span>
							<IconButton onClick={handleCancel} className="profile_close_icon">
								<CloseIcon className="text-white" />
							</IconButton>
						</div>
					</div>
					<div className="bg-dark position-relative">
						<span className="slider_gate"></span>
						<div className="expense-form-cont">
							<InputField
								type="text"
								name="title"
								id="title"
								label="Expense Title"
								value={state?.title || ""}
								required={true}
								onChange={handleChange}
								placeholder="Enter Title"
								className={""}
								inputMode={"text"}
								formSubmitted={formSubmitted}
							/>

							<InputField
								type="number"
								name="amount"
								id="amount"
								label="Amount"
								value={state?.amount || ""}
								required={true}
								onChange={handleChange}
								placeholder="Enter Amount"
								className={""}
								inputMode={"numeric"}
								formSubmitted={formSubmitted}
							/>
							<SelectInput
								options={props.categories.map((cat: ICategory) => ({ value: cat.title, label: cat.title, ...cat }))}
								value={state.category}
								onChange={handleSelect}
								label="Category"
								required={true}
								id="category"
								name="category"
								placeholder="Select Category"
								formSubmitted={formSubmitted}
							/>
							<SelectInput
								options={props.accounts.map((acc: IAccount) => ({ value: acc.title, label: acc.title, ...acc }))}
								value={state.account}
								onChange={handleSelect}
								label="Account"
								required={true}
								id="account"
								name="account"
								placeholder="Select Account"
								formSubmitted={formSubmitted}
							/>
							<DateInput
								value={state.expenseDate}
								onChange={handleSelectDate}
								label="Expense Date"
								required={true}
								id="expenseDate"
								name="expenseDate"
								placeholder="Select Date"
								formSubmitted={formSubmitted}
							/>
							<br />
							<div className="expense-footer d-flex justify-content-between ">
								<button onClick={handleCancel} className="btn btn-secondary line px-4">
									Cancel
								</button>
								<button onClick={handleSubmit} className="btn btn-primary px-4">
									{props.isEdit ? "Update" : "Save"}
								</button>
							</div>
						</div>
					</div>
				</section>
			</div>
			<style>{`
            .body {
				background-color: var(--bg-secondary);
			}
        `}</style>
		</React.Fragment>
	);
};

export default connector(AddEditExpense);
